<template>
  <div class="main-container">
    <div class="section--header">
      <div class="header-action">
        <span><i class="fal fa-users-medical"></i></span>
        {{ $t("empRestDay.addEmp") }}
        <v-btn v-if="selectedEmployees.length > 0" class="btn-save-change btn-right" @click="saveChange()">
          {{ $t('empRestDay.save') }}
        </v-btn>
      </div>
    </div>
    <div class="section--content">
      <div class="section-left">
        <v-form class="form-autocomplete">
          <v-row>
            <v-col cols="12" md="12">
              <v-autocomplete filled chips color="blue-grey lighten-2" label="Select Employee" item-text="name"
                item-value="id" multiple outlined :items="allEmployee" hide-selected v-model="data.employee">
                <template v-slot:selection="data">
                  <v-chip v-bind="data.attrs" :input-value="data.selected" close @click="data.select"
                    @click:close="removeItemFromTable(data.item)">
                    <v-avatar left>
                      <v-img :src="data.item.urlProfile"></v-img>
                    </v-avatar>
                    {{ data.item.name }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content v-text="data.item"></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-avatar>
                      <img :src="data.item.urlProfile" alt="">
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                      <v-list-item-subtitle v-html="data.item.surname"></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </div>
      <div class="section-right" v-if="selectedEmployees.length > 0">
        <div class="show-list-emp-position">
          <table class="table table-view">
            <tbody>
              <tr v-for="(item, idx) in selectedEmployees" :key="idx" class="text-start">
                <td>{{ idx + 1 }}</td>
                <td class="image-circle-emp-position">
                  <img :src="item.urlProfile" alt="" />
                </td>
                <td class="employee-name">
                  <span>{{ item.name }}</span><span>{{ item.surname }}</span>
                </td>
                <td>

                </td>
                <td>

                </td>
                <td class="text-end">
                  <v-menu transition="slide-y-transition" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn @click="removeItemFromTable(item)" outlined v-bind="attrs" v-on="on">
                        <i style="font-size: 22px" class="fas fa-minus-circle"></i>
                      </v-btn>
                    </template>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <loading v-if="isLoading" />
  </div>
</template>

<script>
import Loading from "@/components/Loading";
export default {
  components: {
    Loading
  },
  data() {
    return {
      isLoading: false,
      allEmployee: [],
      employeeRestDay: [],
      data: {
        employee: [],
      }
    }
  },

  computed: {
    selectedEmployees() {
      return this.data.employee.map((id) => {
        const find = this.allEmployee.filter((item) => {
          return id === item.id;
        })
        if (find.length) {
          return find[0];
        }
        return {};
      })
    }
  },

  methods: {
    fetchAllEmployee() {
      this.isLoading = true;
      this.$axios.get(`company/all/employee-name`).then((res) => {
        this.allEmployee = res.data.data;
        this.isLoading = false;
      }).catch(() => {
      })
    },


    removeItemFromTable(item) {
      const index = this.data.employee.indexOf(item.id)
      if (index >= 0) {
        this.data.employee.splice(index, 1)
      }
    },
    saveChange() {
      const id = this.$route.params.holiday_id;
      const results = this.selectedEmployees.map((item) => {
        return item.id;
      })
      this.$axios.post(`company/rest-day/${id}/employees`, { employee_id: results }).then((res) => {
        if (res.data.code === 200) {
          setTimeout(() => {
            this.$router.push({
              name: "disableRestDayEmployee.index"
            })
            this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
          }, 300)
        }
      })
    }


  },


  created() {
    this.fetchAllEmployee();
  }
}
</script>

<style scoped lang="scss">
.main-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;

  .section--header {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    .header-action {
      color: #18BBEC;
      font-size: 24px;
      font-family: $font-family;
      margin-top: 20px;
      margin-left: 40px;

      span {
        margin-right: 10px;
        color: #18BBEC;
        font-size: 30px;
      }

      .btn-right {
        position: absolute;
        right: 40px;
        top: 30px;
      }
    }
  }

  .section--content {
    width: 100%;
    height: auto;
    display: flex;

    .section-left {
      flex: 1;
      padding: 40px;

      .form-autocomplete {}
    }

    .section-right {
      flex: 1;
      padding: 30px 40px 0 0;

      .form-autocomplete {
        margin-top: 20px;
      }

      .image-circle-emp-position img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px;
      }
    }
  }
}
</style>
